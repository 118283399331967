import React from "react"
import { Layout, Title, Cartoon } from "../components/index"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';


export const query = graphql`
  query {
    file(relativePath: { eq: "ANIMIRANI-FILM-konradova-prodavaonica-ptica-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data }) => (
      <div className="animirani-film">
          <Helmet>
                <title>Animated films - Art Organization Creative House</title>
                <meta name='title' content='Animated films - Art Organization Creative House' />
                <meta name='description' content='Creative House is art organization founded in 2018, which deals with the publication and distribution of quality literary works, the production of original animated films and the organization and implementation of creative workshops and events related to literature and animated film. Our mission is to connect reality and imagination into original art expressions and to expand the space of creative freedom. The founders and main implementers of the program of the Art Organization CREATIVE HOUSE are the multi-award winning writer and screenwriter Nena Lončar and the artist Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            </Helmet>
        <Layout>
          <Title title='Animated film' subtitle='Animated films from Creative House'></Title>
          <div className="cartoon-wrapper">
            <Cartoon title={`Konrad's bird shop`}>
              <p>Konrad is a boy who is unusually fond of birds. There are no feathered creatures that he doesn't know how to recognize; from the quite tiny ones that fit in a drop of water to those whose wingspan reaches up to several meters. With ease he recognizes their singing. He is amazed by everything related to these wonderful celestial fliers, especially by the way they move. If he could, he would take off with them himself.</p>
              <p>Konrad, with his elderly grandmother, lives far out of town and everyday he climbs trees. Unless he picks apples or any other fruit for the best cakes in the world baked by his grandmother, he gently returns the birds to the nests from which they sometimes fall. In winter, when they are swept up by the snow, he worries that they have enough food, He says, when they grow up he will be a nanny for birds. People who see him somewhere high up in the treetops often substitute him for a squirrel because of his orange hair.The day Konrad's grandmother has to move to heaven, Konrad has to move to the city to live with Uncle Arthur, away from his birds.</p>
              <p>-the film is in the pre-production phase</p>
            </Cartoon>
            <Img fluid={data.file.childImageSharp.fluid} className='cartoon-img' alt={`Animated film Konrad's bird shop`} />
            </div>
        </Layout>
      </div>
)